<template>
	<div>
		<v-breadcrumbs :items="breadcrumbsItems" class="pl-3">
      <template v-slot:divider>
        <v-icon class="grey--text" x-small>fas fa-chevron-right</v-icon>
      </template>
		</v-breadcrumbs>
		<ConfirmTool ref="confirm"></ConfirmTool>
		<v-container v-if="NutritionistProfile">
			<v-row>
				<v-col cols="2" class="text-center">
					<v-progress-linear
						:indeterminate="true"
						v-show="LoadingData"
					></v-progress-linear>
					<v-img
						@click="pickFile"
						v-if="NutritionistProfile.ImageFileUrl"
						:src="NutritionistProfile.ImageFileUrl"
					>
						<div class="deleteIcon">
							<v-icon color="warning" @click.stop="deleteImage"
								>fas fa-trash-alt</v-icon
							>
						</div>
					</v-img>
					<div
						style="max-width: 200px"
						class="rounded grey lighten-2 px-2 py-5"
						v-else
					>
						<v-icon color="grey darken-2" x-large @click="pickFile"
							>fas fa-user</v-icon
						>
					</div>

					<div style="display: none">
						<v-file-input
							@change="fileUploadSelected"
							accept="image/*"
							v-model="uploadFiles"
							ref="inputFiles"
						></v-file-input>
					</div>
				</v-col>
				<v-col cols="10">
					<h1 class="nutriyou-h1 text-left" v-if="NutritionistProfile">
						{{ NutritionistProfile.ApplicationUser.DisplayName }}
					</h1>
				</v-col>
			</v-row>
		</v-container>

		<SimpleFormCard
			ref="formNutritionistProfile"
			:formContent="formNutritionistProfile"
			:initialValues="NutritionistProfile"
			:metadata="metadata"
			:metadataLoaded="metadataLoaded"
			@submitForm="SaveProfile"
			:showCancelButton="false"
			:locali18n="$i18n"
		></SimpleFormCard>
		<v-divider></v-divider>
		<UserTags :UserId="NutritionistUserId"></UserTags>
		<NutritionistUnavailableDates :NutritionistUserId="NutritionistUserId"></NutritionistUnavailableDates>
	</div>
</template>
<script>

import { mapActions } from "vuex";
import CrudFormData from "@/utilities/CrudFormData.js";
import CrudClient from "@/services/CrudClient/";
import metadata from "@/mixins/metadata";
import SimpleFormCard from "@/components/Shared/FormControl/SimpleFormCard";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import UserTags from "./components/UserTags";
import NutritionistUnavailableDates from "./components/NutritionistUnavailableDates";

export default {
	metaInfo() {
		return {
			title: this.NutritionistProfile?.ApplicationUser?.DisplayName,
		};
	},
	name: "EditNutritionist",
	mixins: [metadata],
	components: { SimpleFormCard, ConfirmTool, UserTags, NutritionistUnavailableDates },
	data() {
		return {
			breadcrumbsItems: [
				{
					text: "Home",
					disabled: false,
					exact: true,
					to: "/",
				},
				{
					text: "Admin",
					disabled: false,
					exact: true,
					to: "/Admin",
				},
			],
			LoadingData: false,
			NutritionistProfile: null,
			formNutritionistProfile: new CrudFormData("formNutritionistProfile", [
				{
					FieldName: "IsActive",
					type: "v-switch",
				},
				{
					FieldName: "PersonalTitle",
					type: "v-select",
					items: ["dottoressa", "dottore"],
				},
				{
					FieldName: "ProfessionalTitle",
					type: "v-select",
					items: [
						"Dietista Nutrizionista",
						"Biologo Nutrizionista",
						"Biologa Nutrizionista",
						"Medico chirurgo specialista in Scienza dell'Alimentazione",
					],
				},
				{
					FieldName: "Bio",
					type: "v-textarea",
				},
				{
					FieldName: "Training",
					type: "v-textarea",
				},
				{
					FieldName: "ProfilePageUrl",
					type: "v-text-field",
				},
				{
					FieldName: "AvailabilityMorning",
					type: "v-checkbox",
				},
				{
					FieldName: "AvailabilityLunchTime",
					type: "v-checkbox",
				},
				{
					FieldName: "AvailabilityAfternoon",
					type: "v-checkbox",
				},
				{
					FieldName: "AvailabilityEvening",
					type: "v-checkbox",
				},
				{
					FieldName: "AvailabilityWeekend",
					type: "v-checkbox",
				},
				{
					FieldName: "CoupleVisits",
					type: "v-checkbox",
				},
				{
					FieldName: "Gender",
					type: "v-select",
					items: [{ Text: "Uomo", Value: 1 }, { Text: "Donna", Value: 2 }],
					"item-text": "Text",
					"item-value": "Value"
				},
				{
					FieldName: "YearOfBirth",
					type: "v-text-field",
					"input-type": "number"
				},
				{
					FieldName: "PriorityPoints",
					type: "v-text-field",
					"input-type": "number"
				},
				{
					FieldName: "CalendarSlotsAvailable",
					type: "v-checkbox",
				},
			]),
			uploadFiles: [],

			titles: ["dottoressa", "dottore"],
		};
	},

	computed: {
		NutritionistUserId() {
			return this.$route.params.UserId;
		},
	},

	created() {
		// add services to this
		this.NutritionistsService = new CrudClient("Nutritionists");
	},
	async mounted() {
		await this.LoadMetadata(this.NutritionistsService);

		this.NutritionistProfile = await this.NutritionistsService.Get(
			this.NutritionistUserId
		);

		this.breadcrumbsItems.push({
			text: this.$t("nutriyou.nutritionists"),
			disabled: false,
			exact: true,
			to: "/Admin/Nutritionists",
		});

		this.breadcrumbsItems.push({
			text: this.NutritionistProfile.ApplicationUser.DisplayName,
			disabled: true,
			exact: true,
			to: "/Admin/NutritionistProfile/" + this.NutritionistUserId,
		});
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async SaveProfile(dto) {
			try {
				this.formNutritionistProfile.FormLoading = true;

				await this.NutritionistsService.Patch(this.NutritionistUserId, dto, false);

				this.NutritionistProfile = await this.NutritionistsService.Get(
					this.NutritionistUserId
				);
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotSaveData") });
			} finally {
				this.formNutritionistProfile.FormLoading = false;
			}
		},

		pickFile() {
			this.$log.debug("click icon");
			this.$refs.inputFiles.$refs.input.click();
		},

		async deleteImage() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("common.deleteImage"),
					"Stai per cancellare l'immagine",
					{ color: "warning" }
				))
			) {
				return;
			}

			try {
				this.LoadingData = true;
				const updatedItem = await this.NutritionistsService.DeleteImage(
					this.NutritionistUserId
				);

				this.NutritionistProfile.ImageFilePath = updatedItem.ImageFilePath;
				this.NutritionistProfile.ImageFileUrl = updatedItem.ImageFileUrl;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotDeleteImage") });
			} finally {
				this.LoadingData = false;
			}
		},

		async fileUploadSelected() {
			try {
				this.LoadingData = true;
				const updatedItem = await this.NutritionistsService.UpdateImage(
					this.NutritionistUserId,
					this.uploadFiles.name,
					this.uploadFiles,
					"image"
				);

				this.NutritionistProfile.ImageFilePath = updatedItem.ImageFilePath;
				this.NutritionistProfile.ImageFileUrl = updatedItem.ImageFileUrl;
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: this.$t("brand.error.cannotSaveData") });
			} finally {
				this.LoadingData = false;
			}
		},
	},
};
</script>
<style lang="scss" scoped>
.deleteIcon {
	position: absolute;
	text-align: right;
	top: 5px;
	right: 5px;

	// max-width: 90%;
	background-color: black;
	padding: 5px 5px;
	border-radius: 4px;
	opacity: 0.4;
}
</style>
<i18n>
{
	"it":{
		"addBillingProfile_Header": "hello",
		"formNutritionistProfile": {
			"IsActive": "Operativo?",
			"PersonalTitle": "Titolo personale (dottoressa)",
			"ProfessionalTitle":"Titolo professionale (Dietista Nutrizionista)",
			"ProfilePageUrl": "URL profilo",
			"Bio": "Bio",
			"Training": "Formazione",
			"Specialization": "Specializatione",
			"AvailabilityMorning": "Disponibile in mattina",
			"AvailabilityLunchTime": "Disponibile Pausa Pranzo",
			"AvailabilityAfternoon": "Disponibile nel pomeriggio",
			"AvailabilityEvening": "Disponibile dopo cena",
			"AvailabilityWeekend": "Disponibile nei weekend",
			"Gender": "Sesso",
			"DefaultChatWelcomeMessage": "Messaggio automatico per nuovi pazienti",
			"YearOfBirth": "Anno di nascita",
			"CoupleVisits": "Visite di coppia",
      "CalendarSlotsAvailable": "Pazienti possono prenotare slot nel calendario"
		}
	}
}
</i18n>
