<template>
	<v-card class="my-2">
		<ConfirmTool ref="confirm"></ConfirmTool>
		<v-container fluid>
			<v-row dense>
				<v-col>
					<h6 class="text-h6">
						<v-icon>fas fa-calendar-alt</v-icon>
						{{ booking.BookingTime | formatDateTime }}
					</h6>
				</v-col>
				<v-col class="text-right">
					<nutriYouBookingTypeChip
						:StatusId="booking.BookingTypeId"
					></nutriYouBookingTypeChip>
				</v-col>
			</v-row>
			<v-row dense>
				<v-col>
					<div>
						<UserAvatarFullname
							:profile="booking.Nutritionist"
						></UserAvatarFullname>
					</div>
					<div>
						<UserAvatarFullname
							:showEmailIdentifier="true"
							:profile="booking.Patient"
						></UserAvatarFullname>
						<v-btn
							:to="`/Users/EditUser/${booking.PatientUserId}`"
							icon
							><v-icon>fas fa-user</v-icon></v-btn
						>
						<v-btn
							:to="
								`/Admin/Patients/${booking.PatientUserId}/Events`
							"
							icon
							><v-icon>fas fa-history</v-icon></v-btn
						>
					</div>
				</v-col>
				<v-col class="text-right">
					<span class="text-h5">
						{{ booking.Price | formatCurrency }}
					</span>
					<div v-if="booking.Discount">
						Coupon: {{ booking.Coupon }}
						<br />
						Sconto: {{ booking.Discount | formatCurrency }}
						<br />
						Importo Pagato:
						{{ booking.TotalPaymentAmount | formatCurrency }}
					</div>
					<br />
					<span class="font-weight-bold">
						{{ booking.Duration }} minuti</span
					>
				</v-col>
			</v-row>
			<v-row>
				<v-col>
					<nutriYouOrderStatusChip
						:StatusId="booking.BookingStatusId"
					></nutriYouOrderStatusChip>
					<v-chip
						small
						label
						class="ml-2 grey--text text--darken-1 font-weight-bold"
					>
						Id: {{ booking.BookingId }}
					</v-chip>
					<div
						class="orange--text text--darken-2  ml-2"
						v-if="booking.BookingCancelReason"
					>
						{{ booking.BookingCancelReason.Name }}
					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-card-actions>
			<v-spacer></v-spacer>

			<v-btn
				color="error"
				@click="ReactivateBooking"
				v-if="booking.BookingStatusId === BookingStatusIdEnum.CANCELLED"
				:loading="reactivatingBooking"
				class="text-capitalize"
				><v-icon left>fas fa-redo</v-icon> {{ $t("ReactivateBooking") }}</v-btn
			>

			<v-btn
				color="warning"
				@click="MarkAsPaid"
				class="text-capitalize"
				v-if="
					booking.BookingStatusId ===
						BookingStatusIdEnum.WAITINGFORPAYMENT
				"
				:loading="sendingMarkAsPaidRequest"
				>{{ $t("MarkAsPaid") }}</v-btn
			>

			<v-btn
				color="info"
				text
				@click="openModifyBookingDialog"
				:loading="sendingModifyRequest"
				class="text-capitalize"
				v-if="
					booking.BookingStatusId === BookingStatusIdEnum.BOOKED ||
						booking.BookingStatusId ===
							BookingStatusIdEnum.WAITINGFORPAYMENT
				"
				>{{ $t("common.edit") }}</v-btn
			>
			<v-btn
				color="success"
				@click="CompleteBooking"
				v-if="booking.BookingStatusId === BookingStatusIdEnum.BOOKED"
				:loading="sendingCompleteRequest"
				class="text-capitalize"
				>{{ $t("common.complete") }}</v-btn
			>

			<v-btn
				text
				color="error"
				v-if="booking.BookingStatusId !== BookingStatusIdEnum.CANCELLED"
				@click="CancelBooking"
				:loading="sendingCancelRequest"
				class="text-capitalize"
				>{{ $t("common.cancel") }}</v-btn
			>
		</v-card-actions>

		<v-dialog max-width="400" v-model="showModifyDialog"
			><v-card
				><v-card-title>Modifica la data e ora </v-card-title>
				<v-card-text>
					<DateTimePicker v-model="newDataTime"></DateTimePicker>
					<!-- {{ booking.BookingTime | formatDateTime }} -->
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="showModifyDialog = false" text>{{
						$t("common.cancel")
					}}</v-btn>
					<v-btn @click="UpdateDateTime" color="primary">{{
						$t("common.update")
					}}</v-btn>
				</v-card-actions>
			</v-card></v-dialog
		>
	</v-card>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";

import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname";
import nutriYouOrderStatusChip from "@/components/NutriYou/nutriYouOrderStatusChip";
import nutriYouBookingTypeChip from "@/components/NutriYou/nutriYouBookingTypeChip";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import DateTimePicker from "@/components/Shared/FormControl/DateTimePicker";

import { BookingStatusIdEnum } from "@/components/NutriYou/nutriYouBookingStatusEnum";

export default {
	props: {
		booking: { type: Object, required: true }
	},

	components: {
		UserAvatarFullname,
		nutriYouOrderStatusChip,
		nutriYouBookingTypeChip,
		ConfirmTool,
		DateTimePicker
	},
	data() {
		return {
			sendingCompleteRequest: false,
			sendingCancelRequest: false,
			sendingMarkAsPaidRequest: false,
			showModifyDialog: false,
			newDataTime: null,
			sendingModifyRequest: false,
			reactivatingBooking: false
		};
	},
	created() {
		this.BookingsService = new CrudClient("Bookings");
		this.BookingStatusIdEnum = BookingStatusIdEnum;
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async CancelBooking() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("CancelBooking"),
					"Stai per annullare l'appuntamento",
					{ color: "error" }
				))
			) {
				return;
			}
			try {
				this.sendingCancelRequest = true;
				await this.BookingsService.Post(
					this.booking.BookingId,
					null,
					"CancelBooking"
				);
				this.$emit("bookingModified");
			} catch (error) {
				this.snackError({
					Text: this.$t("common.error.cannotLoadData")
				});
				this.$captureError(error);
			} finally {
				this.sendingCancelRequest = false;
			}
		},

		async CompleteBooking() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("CompleteBooking"),
					"Sei sicuro di voler completare l'appuntamento?",
					{ color: "warning" }
				))
			) {
				return 1;
			}
			try {
				this.sendingCompleteRequest = true;
				await this.BookingsService.Post(
					this.booking.BookingId,
					null,
					"Complete"
				);
				this.$emit("bookingModified");
			} catch (error) {
				this.snackError({
					Text: this.$t("common.error.cannotLoadData")
				});
				this.$captureError(error);
			} finally {
				this.sendingCompleteRequest = false;
			}
		},

		async ReactivateBooking() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("ReactivateBooking"),
					"Sei sicuro di voler riattivare l'appuntamento?",
					{ color: "warning" }
				))
			) {
				return 1;
			}
			try {
				this.reactivatingBooking = true;
				await this.BookingsService.Post(
					this.booking.BookingId,
					null,
					"Reactivate"
				);
				this.$emit("bookingModified");
			} catch (error) {
				this.snackError({
					Text: this.$t("common.error.cannotLoadData")
				});
				this.$captureError(error);
			} finally {
				this.reactivatingBooking = false;
			}
		},

		async MarkAsPaid() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("MarkAsPaid"),
					"Sei sicuro di voler segnare l'appuntamento come pagato?",
					{ color: "warning" }
				))
			) {
				return;
			}
			try {
				this.sendingMarkAsPaidRequest = true;
				await this.BookingsService.Post(
					this.booking.BookingId,
					null,
					"MarkAsPaid"
				);
				this.$emit("bookingModified");
			} catch (error) {
				this.snackError({
					Text: this.$t("common.error.cannotLoadData")
				});
				this.$captureError(error);
			} finally {
				this.sendingMarkAsPaidRequest = false;
			}
		},
		openModifyBookingDialog() {
			this.showModifyDialog = true;
			this.newDataTime = this.booking.BookingTime;
		},

		async UpdateDateTime() {
			if (
				!(await this.$refs.confirm.open(
					this.$t("ModifyBooking"),
					"Sei sicuro di voler modificare la data dell'appuntamento?",
					{ color: "warning" }
				))
			) {
				return 1;
			}
			try {
				this.sendingModifyRequest = true;
				const dto = { BookingTime: this.newDataTime };
				await this.BookingsService.Post(
					this.booking.BookingId,
					dto,
					"ModifyBookingTime"
				);
				this.$log.debug("bookingModified");
				this.$emit("bookingModified");

				this.showModifyDialog = false;
			} catch (error) {
				this.snackError({
					Text: this.$t("common.error.cannotLoadData")
				});
				this.$captureError(error);
			} finally {
				this.sendingModifyRequest = false;
			}
		}
	}
};
</script>
<i18n>
{
	"it":{
		"MarkAsPaid": "Segna come pagato",
		"CancelBooking": "Annulla l'appuntamento",
		"ReactivateBooking": "Riattiva l'appuntamento"
	}
}
</i18n>
