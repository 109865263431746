<template>
	<v-card>
		<v-card-text>
			<ClientAccountPicker
				RoleName="Nutritionist"
				:label="$t('nutriyou.nutritionist')"
				v-model="nutritionistUserId"
			>
			</ClientAccountPicker>
			<!-- TODO: select only selected Nutritionist's patients -->

			<PatientPicker
				:IsAdmin="true"
				:label="$t('nutriyou.patient')"
				:disabled="!nutritionistUserId"
				v-model="patientUserId"
				@change="onBookingTypeChanged"
			></PatientPicker>

			<BookingTypePicker
				v-model="bookingTypeId"
				@change="onBookingTypeChanged"
			></BookingTypePicker>
			<DateTimePicker v-model="bookingTime"></DateTimePicker>
			<v-text-field
				prepend-icon="fas fa-clock"
				type="number"
				disabled
				v-model="duration"
				:label="$t('common.duration')"
			></v-text-field>
			<v-text-field
				prepend-icon="fas fa-euro-sign"
				type="number"
				disabled
				:label="$t('common.price')"
				v-model="price"
			></v-text-field>

			<v-btn
				color="primary"
				large
				block
				@click="CreateBooking"
				:loading="creatingBooking"
				>{{ $t("createBooking") }}</v-btn
			>
		</v-card-text>
	</v-card>
</template>
<script>
import ClientAccountPicker from "@/components/Account/ClientAccountPicker";
import BookingTypePicker from "@/components/NutriYou/BookingTypePicker";
import DateTimePicker from "@/components/Shared/FormControl/DateTimePicker";
import CrudClient from "@/services/CrudClient/";
import { mapActions } from "vuex";

import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";
import PatientPicker from "@/views/Nutritionists/components/PatientPicker.vue";

export default {
	components: {
		PatientPicker,
		ClientAccountPicker,
		BookingTypePicker,
		DateTimePicker
	},
	data() {
		return {
			patientUserId: null,
			nutritionistUserId: null,
			bookingTypeId: null,
			bookingStatusId: null,
			duration: null,
			price: 0,
			bookingTime: null,
			creatingBooking: false
		};
	},

	created() {
		this.BookingsService = new CrudClient("Bookings");
		this.PatientsService = new CrudClient("Patients");
	},

	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async onBookingTypeChanged() {
			if (this.bookingTypeId === null) {
				return;
			}

			if (this.patientUserId === null) {
				return;
			}

			const res = await this.PatientsService.Get(
				this.patientUserId,
				"Price/" + this.bookingTypeId
			);
			this.price = res.Price;

			if (this.bookingTypeId === BookingTypesEnum.FREE) {
				this.duration = 30;
			} else if (this.bookingTypeId === BookingTypesEnum.NORMAL) {
				this.duration = 50;
			} else if (this.bookingTypeId === BookingTypesEnum.MEALPLAN) {
				this.duration = 30;
			}
		},

		async CreateBooking() {
			const dto = {
				PatientUserId: this.patientUserId,
				NutritionistUserId: this.nutritionistUserId,
				BookingTypeId: this.bookingTypeId,
				BookingTime: this.bookingTime
			};

			try {
				this.creatingBooking = true;
				const bookingId = await this.BookingsService.Post(null, dto);

				this.$emit("bookingCreated", bookingId);

				this.snackSuccess({ Text: this.$t("bookingCreated") });
				this.ClearForm();
			} catch (error) {
				this.snackError({ Text: this.$t("cannotCreateBooking") });
				this.$captureError(error);
			} finally {
				this.creatingBooking = false;
			}
		},

		ClearForm() {
			this.patientUserId = null;
			this.nutritionistUserId = null;
			this.bookingTypeId = null;
			this.bookingStatusId = null;
			this.duration = null;
			this.price = null;
			this.bookingTime = null;
		}
	}
};
</script>
<i18n>
{
	"it": {
		"createBooking": "Crea appuntamento",
		"bookingCreated": "Appunramento Creato!",
		"cannotCreateBooking": "Impossibile creare l'appuntamento"
	}
}
</i18n>
